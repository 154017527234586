import React from "react";
import {Helmet} from "react-helmet";
import {Container} from "react-bootstrap";
import * as style from "./ContactUs.module.scss";
import ContactForm from "./ContactForm";
import HowCome from "./HowCome";

const ContactUs = (props) => {
    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Centro Vacacional Campo Verde / Contactanos</title>
            <link rel="canonical" href="http://centrovacacionalcampoverde.com/contactanos" />
        </Helmet>
        <Container fluid={true} className={style.root} >
            <h1 className={style.title}>Contáctanos</h1>
            <ContactForm />
            <HowCome />
        </Container>
    </>
}

export default ContactUs