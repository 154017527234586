import React from 'react';
import {Helmet} from "react-helmet";
import bg1 from '../../assets/images/gallery/panoramic1.jpg'
import bgmobile1 from '../../assets/images/gallery/panoramic1.jpg'
import bg2 from '../../assets/images/gallery/panoramic2.jpg'
import bgmobile2 from '../../assets/images/gallery/panoramic2.jpg'
import CarouselHome from "./Carousel/Carousel";
import WelcomeSection from "./WelcomeSection";
import ActivitiesSection from "./ActivitiesSection";
function Home() {
    const slides = [
        {
            img:bg1,
            img_mobile: bgmobile1,
            cardContent:{
                title:"Pasadía",
                items: [
                    "Acceso a la Piscina",
                    "Restaurante",
                    "Zona de juegos"
                ],
            }
        },
        {
            img:bg2,
            img_mobile: bgmobile2,
            cardContent:{
                title:"Hospedaje",
                items: [
                    "Habitación con camas",
                    "Acceso a Piscina",
                    "Acceso a Restaurante",
                    "Bar"
                ],
            }
        },
    ]
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Centro Vacacional Campo Verde</title>
                <link rel="canonical" href="http://centrovacacionalcampoverde.com/" />
            </Helmet>
            <CarouselHome slides={slides} />
            <WelcomeSection />
            <ActivitiesSection  />
        </>
    );
}

export default Home;
