import {Carousel, Button} from "react-bootstrap";
import * as style from './Carousel.module.scss'
import React from "react";
import {BrowserView, MobileView} from "react-device-detect";

const CarouselHome = ({ slides })=>{
    return <Carousel variant="dark">
        {slides.map((slide, id)=>(
            <Carousel.Item key={id}>
                <BrowserView>
                    <img
                        className="d-block w-100"
                        style={{
                            maxHeight:"700px",
                            objectFit: "cover"
                        }}
                        src={slide.img}
                        alt="First slide"
                    />
                </BrowserView>
                <MobileView>
                    <img
                        className="d-block w-100"
                        src={slide.img_mobile}
                        alt="First slide"
                    />
                </MobileView>
                <h2 className={style.titleCaption}>{slide.cardContent.title}</h2>
                <Carousel.Caption className={style.sectionCard}>
                    <h3 className={style.title}>Plan incluye</h3>
                    <ul className={style.content}>
                        {slide.cardContent.items.map((itm,idx)=><li key={idx}>{itm}</li>)}
                    </ul>
                    <Button className={style.linkReserve} variant={"link"}>¡Reserve Ahora!</Button>
                </Carousel.Caption>
            </Carousel.Item>
        ))}
    </Carousel>
}

export default CarouselHome;