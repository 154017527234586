import React from "react";
import {Helmet} from "react-helmet";
import WelcomeSection from "./WelcomeSection";
import WhyChoiceUsSection from "./WhyChoiceUsSection";
import * as style from './About.module.scss'
import {Container} from "react-bootstrap";
import GallerySection from "./GallerySection";
import panoramic1 from "../../assets/images/gallery/panoramic1.jpg";
//import panoramic2 from "../../assets/images/gallery/panoramic2.jpg";
import panoramic3 from "../../assets/images/gallery/panoramic3.jpg";
import pool1 from "../../assets/images/gallery/pool1.jpg";
//import pool2 from "../../assets/images/gallery/pool2.jpg";
import room1 from "../../assets/images/gallery/room1.jpg";
import room2 from "../../assets/images/gallery/room2.jpg";

const images = [
    {
        url:panoramic1,
        alt: "Centro Vacacional Campo Verde",
    },
    {
        url:pool1,
        alt: "Servicio de Piscina",
    },
    {
        url:room1,
        alt: "Excelente habitaciones",
    },
    {
        url:panoramic3,
        alt: "Eventos y Pasadias",
    },
    {
        url:room2,
        alt: "Piscina",
    },

]

const About = (props) => {
    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Centro Vacacional Campo Verde / Quienes Somos</title>
            <link rel="canonical" href="http://centrovacacionalcampoverde.com/quienes-somos" />
        </Helmet>
        <Container fluid={true}  className={style.root} >
            <WelcomeSection />
            <WhyChoiceUsSection />
            <GallerySection images={images}/>
        </Container>
    </>
}

export default About