import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import desktopLogo from '../assets/images/Logo_desktop.png'
import mobileLogo from '../assets/images/Logo_2_mobile.png'
import {Image} from "react-bootstrap";

function Logo() {
    return <>
        <BrowserView>
            <Image rounded src={desktopLogo} alt={"Centro Vacacional Campo Verde"}/>
        </BrowserView>
        <MobileView>
            <Image rounded src={mobileLogo} alt={"Centro Vacacional Campo Verde"}/>
        </MobileView>
    </>;
}

export default Logo;