import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {BrowserView, MobileView} from "react-device-detect";
import * as style from './WelcomeSection.module.scss'
const WelcomeSection = (props) => {
    return <>
        <Container className={style.root}>
            <Row>
                <Col xs={12}>
                    <h2 className={style.welcomeTitle}>Bienvenidos a</h2>
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    <BrowserView>
                        <h3 className={style.nameTitle1} md={6} xs={12}>CAMPO</h3>
                        <h2 className={style.nameTitle2} md={6} xs={12}>VERDE</h2>
                    </BrowserView>
                    <MobileView>
                        <h2 className={style.nameTitle}>CAMPO VERDE</h2>
                    </MobileView>

                </Col>
                <Col className={style.content} md={6} xs={12}>
                    Sé bienvenido a un lugar mágico de relajación y descanso donde podras disfrutar de momentos especiales y recuperar energías
                </Col>
            </Row>
        </Container>
        <Container fluid={true} className={style.embededVideo}>
            <Row className={style.row}>
                <iframe width="1280" height="720" src="https://www.youtube.com/embed/c_vNa0wJdKY"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </Row>
        </Container>
        </>
}
export default WelcomeSection;