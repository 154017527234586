import React, {useEffect, useState} from "react";
import {Button, Col, Image, Row} from "react-bootstrap";
import * as style from './ActivitiesSection.module.scss';
import {isBrowser, isMobile} from "react-device-detect";

const ActivityCard = ({idx, name, image, description, url}) => {
    const [side, setSide] = useState('left')
    useEffect(()=>{
        setSide(idx%2===0?'left':'right')
    },[side, idx])
    return <Row  className={"mt-5"}>
        {((idx % 2 === 0 && isBrowser) || isMobile) && <>
            <Col className={"text-center text-md-right"} xs={12} md={4}>
                <Image className={style.imageService} rounded src={image}/>
            </Col>
            <Col xs={12} md={7}>
                <h3 className={style.titleService}>{name}</h3>
                <p>{description}</p>
                <Button className={style.ButtonMore} variant={"warning"} size="lg">Ver más</Button>
            </Col>
        </>
        }
        {idx % 2 !== 0 && isBrowser &&  <>
            <Col xs={12} md={{offset: 2, span:6}}>
                <h3 className={style.titleService}>{name}</h3>
                <p>{description}</p>
                <Button className={style.ButtonMore} variant={"warning"} size="lg">Ver más</Button>
            </Col>
            <Col className={"text-center text-md-right"} xs={12} md={3}>
            <Image className={style.imageService} rounded src={image}/>
            </Col>
</>
        }
    </Row>
}

export default ActivityCard;