import React from 'react';
import { Routes, Route } from "react-router-dom";
import {Helmet} from "react-helmet";
import './App.css';
import Navbar from "./components/Header/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";


function App() {
    const pages = [
        {
            title:"Inicio",
            url:"/",
            component: <Home />
        },{
            title:"Quienes Somos",
            url:"/quienes-somos",
            component: <About />
        },{
            title:"Planes",
            url:"/planes"
        },{
            title:"Protocolos",
            url:"/protocolos"
        },{
            title:"Contactenos",
            url:"/contactenos",
            component: <ContactUs />
        },
    ]
  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Centro Vacacional Campo Verde</title>
            <link rel="canonical" href="http://centrovacacionalcampoverde.com/example" />
        </Helmet>
        <Navbar pages={pages} />
        <Routes>
            {pages.map((page, idx)=><Route path={page.url} element={page.component} key={idx} />)}

        </Routes>
        <Footer />
    </>
  );
}

export default App;
