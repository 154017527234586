import React from "react";
import Logo from "../Logo";
import {Link} from "react-router-dom";
import FacebookIcon from "../../assets/images/social/Facebook_1.png";
import IGIcon from "../../assets/images/social/Instagram_1.png";
import {Container, Image, Nav, Navbar} from "react-bootstrap";

const NavbarCustom = ({pages}) => {
    return (<>
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/"><Logo /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" style={{
                    justifyContent: 'flex-end',
                }}>
                    <Nav className="me-auto">
                        {pages.map(({title, url})=>(<Nav.Link className={"m-md-3"} key={title} as={Link} to={url}>{title}</Nav.Link>))}
                        <Nav.Link  href={''}><Image src={FacebookIcon} alt={"Campo Verde en Facebook"} /></Nav.Link>
                        <Nav.Link  href={'https://www.instagram.com/centro_vacacional_campoverde'} target={"_blank"}><Image src={IGIcon} alt={"Campo Verde en Instagram"} /></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    </>)
}

export default NavbarCustom;