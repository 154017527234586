import React from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import * as style from './WhyChoiceUsSection.module.scss'
import logo from '../../../assets/images/Logo_desktop.png'
const whyUsOption = [
    {
        number: 1,
        text: "Ofrecemos todos los servicios que requieres para hacer de tu visita uno de los mejores recuerdos que puedas tener"
    },{
        number: 2,
        text: "Nos preocupamos por la seguridad y la salud de nuestros visitante, mantenemos todos los protocolos de bioseguridad, cuidado y limpieza de nuestras instalaciones"

    },{
        number: 3,
        text: "Somos gente amable, queremos que te sientas lo mejor atendido posible y ponemos a nuestros visitantes en el centro de todo"
    },
]
const WhyChoiceUsSection = (props) => {
    return <Container className={style.root}>
            <Row>
                <Col xs={12} md={6} className={style.rootWhy}>
                    <h2 className={style.whyUsTitle}>Por qué elegirnos</h2>
                    {whyUsOption.map((content, idx)=>
                        <Row key={idx}>
                            <Col xs={12} className={style.number}>{content.number}</Col>
                            <Col>{content.text}</Col>
                        </Row>
                    )}
                </Col>
                <Col xs={12} md={6} className={style.contentTwo}>
                    <Image src={logo} className={"my-auto"} alt={"Campo Verde Centro Vacacional"}/>
                    <h4 className={style.simpleMessage}>Es una
                        oportunidad de
                        desconexión,
                        pausa y
                        renovación.</h4>
                </Col>
            </Row>
        </Container>
}
export default WhyChoiceUsSection;