import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import ActivityCard from './ActivityCard'
import * as style from './ActivitiesSection.module.scss';
import img1 from '../../../assets/images/services/Imag_campo_4.jpg'
import img2 from '../../../assets/images/services/Imag_campo_5.jpg'
import img3 from '../../../assets/images/services/Imag_campo_6.jpg'
import img4 from '../../../assets/images/services/Imag_campo_16.jpg'

const activities = [
    {
        name:"Eventos",
        description:"Organiza tus eventos en nuestros centros de convenciones dotados y acompañados con todo lo que necesitas para ese momento tan especial.",
        image:img2,
        url:"/eventos"
    },
    {
        name:"Salidas",
        description:"Como parte de nuestro servicios puedes encontrar salidas guiadas por los municipios aledaños para que tu visita sea más interesante",
        image:img4,
        url:"/salidas"
    },
    {
        name:"Celebraciones",
        description:"En las fechas importantes podrás disponer de todo lo que necesites para que esa celebración sea memorable y que puedas disfrutar al máximo de ese momento especial",
        image:img3,
        url:"/celebariones"
    },
    {
        name:"Hotelería",
        description:"Contamos con habitaciones espectaculares para que puedas descansar y pasar momentos amenos en nuestras instalaciones",
        image:img1,
        url:"/caminatas"
    },

];

const ActivitiesSections = (props) => {
    return <Container fluid={true} className={style.root}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h2 className={style.welcomeTitle}>ACTIVIDADES</h2>
                    </Col>
                </Row>
            </Container>
            <Container fluid={true} >
                {activities.map((activity, idx)=><ActivityCard {...activity} idx={idx} key={idx} /> )}
            </Container>
        </Container>
}
export default ActivitiesSections;