import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import * as style from './WelcomeSection.module.scss'
const WelcomeSection = (props) => {
    return <Container className={style.root}>
            <Row>
                <Col xs={12}>
                    <h1 className={style.welcomeTitle}>¿Quienes Somos?</h1>
                </Col>
                <Col xs={12}>
                    <h2 className={style.subWelcome}>BIENVENIDOS</h2>
                </Col>
            </Row>
            <Row>
                <Col md={4} xs={12}>
                    <h3 className={style.someWords}>Unas Palabras</h3>
                </Col>
                <Col md={4} xs={6}>
                    Estamos felices de compartir los mejores momentos contigo porlo que te invitamos a que disfrutes de nuestras instalaciones y servicios, estar en busca de un momento diferente para la vida hace  que indaguemos en recorramos caminos que no imaginamos que podríamos visitar
                </Col>
                <Col className={style.content} md={4} xs={6}>
                    Al descubrir estos nuevos lugares nuestra alma se llena de felicidad y de paz, en nuestro centro vacacional quieremos que vivas estos momentos magicos acompanado de todas las comodides y de todo lo que necesitas para vivir esa experiencia memorable, esa es nuestra meta.
                </Col>
            </Row>
        </Container>
}
export default WelcomeSection;