import React, { useRef, useState } from 'react'
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import * as style from '../ContactUs.module.scss'
import axios from "axios";
const ContactForm = (props) => {
    const inputEl1 = useRef(null);
    const inputEl2 = useRef(null);
    const inputEl3 = useRef(null);
    const inputEl4 = useRef(null);
    const [formInfo, setFormInfo] = useState({})
    const [sended, setSended] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState("")
    const sendMessage = (e) =>{
        setSending(true)
        setError("")
        e.preventDefault()
            axios.post("https://www.centrovacacionalcampoverde.com/api/public/api/contact-us", formInfo).then((response)=>{
                console.log(response.data)
                setSended(true)
                setSending(false)
                inputEl1.current.value="";
                inputEl2.current.value="";
                inputEl3.current.value="";
                inputEl4.current.value="";
            }).catch(e => {
                setError(e)
                setSending(false)
        })
    }
    const addValue = (e) => {
        const fieldName= e.target.name
        console.log(e.target)
        setFormInfo({
            ...formInfo,
            [fieldName]: e.target.value
        })
    }
    return <Container className={"mb-4"}>
        <Row>
            <Col>
                <h2 className={style.subtitle}>Envíanos un Mensaje</h2>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={{span: 6, offset: 3}} className={"mt-3"}>
                <Form onSubmit={sendMessage}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                         <Form.Control disabled={sending} className={style.input} ref={inputEl1} name={"Nombre"} onChange={addValue} size={"lg"} type="text" placeholder="Nombre" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Row>
                            <Col md={6} className={"mb-3"}>
                                <Form.Control disabled={sending} className={style.input} ref={inputEl2} name={"Teléfono"} onChange={addValue} size={"lg"} type="number" placeholder="Teléfono" />
                            </Col>
                            <Col md={6}>
                                <Form.Control disabled={sending} className={style.input} ref={inputEl3} name={"Correo"} onChange={addValue} size={"lg"} type="email" placeholder="Correo" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Control as="textarea" disabled={sending} className={style.input} ref={inputEl4} rows={3} placeholder="Mensaje" onChange={addValue} name={"Mensaje"} size={"lg"} />
                    </Form.Group>
                    <Row>
                        <Col xs={12} style={{
                            textAlign:"center"
                        }}>
                            <Button size={"lg"} variant="warning" disabled={sending} className={style.button} type="submit">
                                Submit
                            </Button>
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col className={"text-center"}>
                            {sending && <Spinner animation="border" /> }
                            {sended && <Alert variant={'success'} sm={12} >Mensaje enviado satisfactoriamente!</Alert> }
                            {error && <Alert variant={'danger'}  sm={12}>El mensaje no fue enviado, intente más tarde!</Alert> }
                        </Col>
                    </Row>
                </Form>
            </Col>

        </Row>

        </Container>
}

export default ContactForm