import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import * as style from './footer.module.scss';
import {FaFacebookSquare, FaInstagramSquare, FaTwitterSquare, FaYoutubeSquare} from "react-icons/all";

const Footer = () => {
    return <Container fluid={true} className={style.root}>
        <Row>
            <Col xs={6} md={4}>
                <Container>
                    <h3>Contacto</h3>
                    <div className={style.detailsFooter}>
                        <p>Teléfono: (+57) 322 614 3787</p>
                        <p><a className={"text-white"} href={"https://wa.me/+573226143787"} target={"_blank"} rel="noopener noreferrer" >WhatApp: +573226143787</a></p>
                        <p>Correo: contacto@centrovacacionalcampoverde.com</p>
                    </div>
                </Container>
            </Col>
            <Col xs={6} md={4}>
                <Container>
                    <h3>Horarios</h3>
                    <div className={style.detailsFooter}>
                        <p>Lunes a Sábado 07:00 am - 06:00 pm</p>
                        <p>Domingos y Festivos 08:00 am - 06:00 pm</p>
                        <p>&nbsp;</p>
                        <p>Cocorná, Antioquia</p>
                    </div>
                </Container>
            </Col>
            <Col xs={12} md={4}>
                <Container>
                    <h3>Síguenos en</h3>
                    <div className={style.detailsFooterSocial}>
                        <FaFacebookSquare />
                        <FaTwitterSquare />
                        <a href={"https://www.instagram.com/centro_vacacional_campoverde"} target={"_blank"} rel="noopener noreferrer" className={"text-white"}><FaInstagramSquare /></a>
                        <FaYoutubeSquare />
                    </div>
                </Container>
            </Col>
        </Row>
        <Row className={"mt-5"}>
            <Col className={"text-center"}>
                © 2022 | Campo Verde
            </Col>
        </Row>
    </Container>
}

export default Footer;