import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import { FaMapMarked, FaWaze} from 'react-icons/fa'
import * as style from '../ContactUs.module.scss'
const HowCome = () => {
    return <>
        <Container className={"text-center"}>
            <Row>
                <Col xs={12}>
                    <h2 className={style.subtitle}>Cómo Llegar</h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    Si deseas información adicional sobre nuestra ubicación, no dudes en contactarnos o utilizar las apps de <strong>Google Maps</strong> y <strong>WAZE</strong>.
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <a rel="noopener noreferrer" href={"https://www.google.com/maps/place/6°00'10.3%22N+75°06'44.3%22W/@6.0028617,-75.114497,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x7812ac6230b17c05!8m2!3d6.0028617!4d-75.1123083?hl=es"} target={"_blank"}>
                    <FaMapMarked className={style.iconLocation} />
                    </a>
                </Col>
                <Col xs={6}>
                    <a rel="noopener noreferrer" href={"https://ul.waze.com/ul?place=ChIJ7YP4kOqxRo4Rqb96PPosVxA&ll=6.00307460%2C-75.11285050&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"} target={"_blank"}>
                        <FaWaze  className={style.iconLocation} />
                    </a>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3 className={style.textCity}>Cocorná, Antioquia</h3>
                </Col>
            </Row>
        </Container>
        <Container>
            <div className="mapouter">
                <div className="gmap_canvas">
                    <iframe
                      title={"location"}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3967.9408432105597!2d-75.11449698523191!3d6.002861695652243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e46b1ea90f883ed%3A0x10572cfa3c7abfa9!2sCentro%20Vacacional%20Campo%20Verde!5e0!3m2!1ses!2sco!4v1644049424146!5m2!1ses!2sco"
                      width="100%" height="400px" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" allowFullScreen="" loading="lazy"></iframe>
                </div>
            </div>
        </Container>
    </>
}

export default HowCome