import React from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import * as style from './Gallery.module.scss'
const GalleryComponent = ({images, fluid}) => {
    return <Container fluid={fluid} className={style.root}>
        <Row>
            <Col md={4} xs={12} style={{ paddingLeft:"1px", paddingRight:"1px" }}>
                <Image className={style.firstImage} src={images[0].url} alt={images[0].alt} />
            </Col>
            <Col  md={4} xs={12} style={{ paddingLeft:"1px", paddingRight:"1px" }}>
                <Container fluid={true}>
                    <Row>
                        <Col xs={12} style={{ paddingLeft:"1px", paddingRight:"1px", paddingBottom:"3px" }}><Image  className={style.secondaryImageT} src={images[1].url} alt={images[1].alt} /></Col>
                        <Col xs={12} style={{ paddingLeft:"1px", paddingRight:"1px" }}><Image  className={style.secondaryImageB} src={images[2].url} alt={images[2].alt} /></Col>
                    </Row>
                </Container>
            </Col>
            <Col  md={4} xs={12} style={{ paddingLeft:"1px", paddingRight:"1px" }}>
                <Container fluid={true}>
                    <Row>
                        <Col xs={12} style={{ paddingLeft:"1px", paddingRight:"1px", paddingBottom:"3px"}}><Image  className={style.finalImageT} src={images[3].url} alt={images[3].alt} /></Col>
                        <Col xs={12} style={{ paddingLeft:"1px", paddingRight:"1px" }}><Image  className={style.finalImageB} src={images[4].url} alt={images[4].alt} /></Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>
}

export default GalleryComponent;